<template>
	<a-card :bordered="false" class="header-solid h-full" :bodyStyle="{paddingTop: 0, paddingBottom: '16px' }">
		<template #title>
			<h6 class="font-semibold m-0">{{$t('volume.list.title')}}</h6>
		</template>
		<a-button type="primary" slot="extra" size="small" @click="createVolume">{{$t('volume.list.btnCreate')}}</a-button>
		<CardProcess @volumeReload="getData" @resizeVolumeReload="getData" />
		<a-row :gutter="[24, 24]" v-if="!fetchListing">
			<a-col :span="24" v-for="row in rows">
				<a-card :bordered="false" class="card-billing-info">
					<a-badge v-if="row.status === VOLUME_ACTIVE" status="success" />
					<a-badge v-else-if="row.status === VOLUME_ERROR" status="error" />
					<a-badge v-else status="default" />
					<div class="col-info">
						<a-descriptions :title="row.name" :column="1">
							<a-descriptions-item :label="$t('volume.list.size')">
								{{row.size}} {{STORAGE_UNIT}} {{row.volume_type.toUpperCase()}}
							</a-descriptions-item>
							<a-descriptions-item :label="$t('volume.list.serverAttach')">
								<span v-if="!_.isEmpty(row.server)">{{_.get(row.server, 'name')}}</span>
							</a-descriptions-item>
							<a-descriptions-item :label="$t('volume.list.created')">
								{{row.created | moment("HH:mm DD-MM-YYYY")}}
							</a-descriptions-item>
						</a-descriptions>
					</div>
					<div class="col-action">
						<a-dropdown placement="bottomRight">
							<a-menu slot="overlay" @click="selectAction">
								<a-menu-item :key="updateInfo.key" :value="{id: row.id, name: row.name}">{{$t('update')}}</a-menu-item>
								<a-menu-item :disabled="row.status !== VOLUME_AVAILABLE" :key="attach.key" :value="{id: row.id, name: row.name}">{{$t('volume.list.attach')}}</a-menu-item>
								<a-menu-item :disabled="row.is_rootdisk || row.status !== VOLUME_ACTIVE" :key="detach.key" :value="{id: row.id, name: row.name, server_id: row.server_id}">{{$t('volume.list.detach')}}</a-menu-item>
								<a-menu-item :disabled="row.is_rootdisk || row.status !== VOLUME_ACTIVE && row.status !== VOLUME_AVAILABLE" :key="resize.key" :value="{id: row.id, name: row.name, disk: row.size, disk_type: row.volume_type}">{{$t('volume.list.extendSize')}}</a-menu-item>
								<a-menu-item :key="restore.key" :value="{id: row.id, name: row.name, server_id: row.server_id}">{{$t('volume.restore.title')}}</a-menu-item>
								<a-menu-item :disabled="row.is_rootdisk || row.status !== VOLUME_AVAILABLE" :key="var_delete.key" class="text-danger" :value="{id: row.id, name: row.name}"><a-badge status="error" /> {{$t('volume.list.delete')}}</a-menu-item>
							</a-menu>
							<a-button size="small"> {{$t('action')}} <a-icon type="down" /> </a-button>
						</a-dropdown>
					</div>
				</a-card>
			</a-col>
			<a-empty v-if="_.isEmpty(rows)" />
		</a-row>
		<a-spin v-else tip="Loading..."></a-spin>

		<Attach v-model="attach.value" @reload="getData" />
		<Resize v-model="resize.value" @reload="getData" />
		<UpdateInfo v-model="updateInfo.value" @reload="getData" />
		<Restore v-model="restore.value" />

	</a-card>
</template>

<script>
	import listing from "@/services/volume/listing"
	import detach from "@/services/volume/detach"
	import func_delete from "@/services/volume/delete"
	import retrieve from "@/services/retrieve/create"
	import {VOLUME_ACTIVE, VOLUME_AVAILABLE, VOLUME_ERROR, STORAGE_UNIT, ORDER_TYPE_VOLUME} from "@/constant"
	import Attach from "@/components/Volume/Attach"
	import Restore from "@/components/Volume/Restore"
	import Resize from "@/components/Volume/Resize"
	import UpdateInfo from "@/components/Volume/Update"
	import { notification } from 'ant-design-vue'
	import CardProcess from "@/components/Cards/CardProcess"

	export default ({
		components: {
			Attach,
			Restore,
			Resize,
			UpdateInfo,
			CardProcess
		},
		data() {
			return {
				fetchListing: true,
				rows: [],
				VOLUME_ACTIVE,
				VOLUME_AVAILABLE,
				STORAGE_UNIT,
				VOLUME_ERROR,
				attach: {
					value: {},
					key: 'attach'
				},
				detach: {
					key: 'detach'
				},
				var_delete: {
					key: 'delete'
				},
				restore: {
					key: 'restore',
					value: {}
				},
				resize: {
					key: 'resize',
					value: {}
				},
				updateInfo: {
					key: 'update',
					value: {}
				}
			}
		},
		created() {
			this.getData()
		},
		methods: {
			getData() {
				this.fetchListing = true
				listing()
				.then(res=> {
					if (this._.isArray(res)) {
						this.rows = res
					}
					this.fetchListing = false
				})
			},
			createVolume() {
				this.$router.push({name: 'volume.create'})
			},
			selectAction(e) {
				const value = e.item.value
				switch(e.key) {
					case this.attach.key:
						this.attach.value = value
					break;
					case this.restore.key:
						this.restore.value = value
					break;
					case this.resize.key:
						this.resize.value = value
					break;
					case this.updateInfo.key:
						this.updateInfo.value = value
					break;
					case this.detach.key:
						this.$confirm({
							title: `${this.$t('volume.detach.title')} ${this.$t('volume.detach.to')} server [${value.name}]`,
							content: this.$t('volume.detach.sure'),
							okText: this.$t('volume.detach.btn'),
							okType: 'danger',
							cancelText: this.$t('popup.cancel'),
							onOk: async ()=> {
								const res = await detach(value.id, value.server_id)
								if (this._.isObject(res)) {
									notification.success({
										message: this.$t('notify.success.detachVolume')
									})
									this.getData()
								}
							}
						});
					break;
					case this.var_delete.key:
						const h = this.$createElement
						this.$confirm({
							title: `${this.$t('volume.actionDelete.title')} [${value.name}]`,
							content: h('div', {}, [
								h('p', this.$t('volume.actionDelete.sure')),
								h('p', this.$t('volume.actionDelete.warning')),
							]),
							okText: this.$t('volume.actionDelete.btn'),
							okType: 'danger',
							cancelText: this.$t('popup.cancel'),
							onOk: async ()=> {
								const res = await retrieve({
									reference_ids : [value.id],
									type : ORDER_TYPE_VOLUME
								})
								if (this._.isObject(res)) {
									notification.success({
										message: this.$t('notify.success.retrieveVolume')
									})
									// this.getData()
								}
							}
						});
						// const h = this.$createElement
						// this.$confirm({
						// 	title: `${this.$t('volume.actionDelete.title')} [${value.name}]`,
						// 	content: h('div', {}, [
						// 		h('p', this.$t('volume.actionDelete.sure')),
						// 		h('p', this.$t('volume.actionDelete.warning')),
						// 	]),
						// 	okText: this.$t('volume.actionDelete.btn'),
						// 	okType: 'danger',
						// 	cancelText: this.$t('popup.cancel'),
						// 	onOk: async ()=> {
						// 		const res = await func_delete(value.id)
						// 		if (this._.isObject(res)) {
						// 			notification.success({
						// 				message: this.$t('notify.success.deleteVolume')
						// 			})
						// 			this.getData()
						// 		}
						// 	}
						// });
					break;
				}
			}
		}
	})

</script>