<template>
	<div>
		<a-row type="flex" :gutter="24">
            <a-col :span="24" :md="16" class="mb-20">
                <CardVolumeInfo />
            </a-col>
			<a-col :span="24" :md="8" class="mb-24">
			</a-col>
		</a-row>
	</div>
</template>

<script>

	import CardVolumeInfo from "@/components/Cards/CardVolumeInfo"

	export default ({
		components: {
			CardVolumeInfo,
		},
		data() {
			return {}
		},
	})

</script>