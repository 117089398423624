<template>
    <a-modal 
        :title="$t('update') + ` volume [${value.name}]`"
        v-model="visible"
        :okText="$t('popup.confirm')"
        :cancelText="$t('popup.cancel')"
        :confirmLoading="confirmLoading"
        @ok="handleSubmit"
        @cancel="handleCancel"
        >
        <a-form :form="volumeInfo" @submit="handleSubmit">
            <a-form-item :label="$t('volume.actionCreate.form.title')">
                <a-input
                v-decorator="['name', { rules: rules.name }]"
                />
            </a-form-item>
        </a-form>
    </a-modal>
</template>
<script>
import updateInfo from "@/services/volume/update"
import { notification } from 'ant-design-vue'
import {PATTERN_NAME} from "@/constant"

export default {
    props: {
        value: {
            type: Object,
            default: ()=> ({})
        }
    },
    watch: {
        value: function(v) {
            if (this._.isEmpty(v)) return
            this.showModal()
        }
    },
    data() {
        return {
            visible: false,
            volumeInfo: this.$form.createForm(this, { name: 'volumeInfo' }),
            rules: {
                name: [
                    {required: true, message: this.$t('rulesForm.requiredServerName'), trigger: ['blur', 'change']},
                    {min: 3, max: 32, message: this.$t('rulesForm.invalidServerCharactersLimited'), trigger: ['blur', 'change']},
                    { pattern: PATTERN_NAME,
                    message: this.$t('rulesForm.invalidServerName'),
                    trigger: ['blur', 'change']
                    }
                ]
            },
            loadingSnapshot: false,
            confirmLoading: false,
            snapshots: []
        };
    },
    methods: {
        showModal() {
            this.visible = true;
            setTimeout(()=> {
                this.volumeInfo.setFieldsValue({
                    name: this.value.name
                })
            }, 1)
        },
        handleCancel(e) {
            this.$emit('input', {})
            this.visible = false;
        },
        handleSubmit(e) {
            e.preventDefault();
            this.volumeInfo.validateFields((err, values) => {
                if (err) {
                    return;
                }
                this.confirmLoading = true
                updateInfo(this.value.id, values)
                .then(res => {
                    this.confirmLoading = false
                    if (this._.isObject(res)) {
                        this.visible = false
                        notification.success({
                            message: this.$t('volume.update.successNotify')
                        })
                        this.$emit('reload')
                    }
                })
            });
        },
    }
};
</script>