<template>
    <a-modal 
        :title="$t('volume.extend.title') + ` (${value.name} -  ${value.disk} GB ${value.disk_type})`"
        v-model="visible"
        :okText="$t('server.resizeAction.btnConfirm')"
        :cancelText="$t('popup.cancel')"
        :confirmLoading="fetchCreateOrder"
        :okButtonProps="{ props: { disabled: disableCreateOrder } }"
        @ok="createOrder"
        @cancel="handleCancel"
        >
        <a-card :bordered="false" class="mb-15">
            <WidgetSliderConfig 
                v-model="form.config"
                :show="{
                    cpu: false,
                    ram: false,
                    disk: true,
                }"
                :range_custom="{
                    disk_min: (this.value ? this.value.disk : 0)
                }"
            />
            <div class="mb-1 mt-20">
                <h6 class="font-semibold mb-10">{{$t('server.resizeAction.invoiceSidebar.currentPack')}}</h6>
                <table v-if="!_.isEmpty(order_current)">
                  <tr v-for="product in order_current.products">
                    <td>{{product.name}}:</td>
                    <td class="price">{{product.value}}</td>
                  </tr>
                  <tr>
                    <td><b>{{order_current.interval_text}}:</b></td>
                    <td class="price"><b> {{currency(order_current.total_money)}} VNĐ</b></td>
                  </tr>
                </table>
              </div>
              <hr class="mt-15">
              <div class="mb-1" v-if="!_.isEmpty(money_info)">
                <h6 class="font-semibold mb-15 mt-10">{{$t('server.resizeAction.invoiceSidebar.newPack')}}</h6>
                <table>
                  <tr>
                    <td>Volume:</td>
                    <td class="price">{{form.config.disk}} {{form.config.disk_type.toUpperCase()}}</td>
                  </tr>
                  <tr>
                    <td><b>{{money_info.label}}:</b></td>
                    <td class="price"><b> {{currency(money_info.total_money)}} VNĐ</b></td>
                  </tr>
                </table>
              </div>
            <a-row v-if="!_.isEmpty(money_info)" type="flex" justify="center" :gutter="24" class="mt-10 mb-30">
                <a-col :span="18" class="text-center mt-20">
                    <a-descriptions :column="1">
                    <a-descriptions-item label="Tổng tiền">
                    {{currency(money_info.total_money)}} VNĐ
                    </a-descriptions-item>
                    <a-descriptions-item label="Chiết khấu">
                    {{currency(order_current.refund_money)}} VNĐ
                    </a-descriptions-item>
                    <a-descriptions-item label="Cần thanh toán">
                        <h5 class="font-semibold text-primary">{{currency(money_info.total_money-order_current.refund_money)}} VNĐ</h5>
                    </a-descriptions-item>
                    </a-descriptions>
                </a-col>
            </a-row>
        </a-card>
    </a-modal>
</template>
<script>
  import OrderService from '@/services/order'
  import orderCurrent from "@/services/order/resources"
  import WidgetSliderConfig from "@/components/Widgets/WidgetSliderConfig"
  import { notification } from 'ant-design-vue'
  import {ORDER_TYPE_VOLUME, ORDER_RESIZE_VOLUME} from "@/constant"
  import {currency} from "@/helper"

export default {
    components: {
        WidgetSliderConfig,
    },
    props: {
        value: {
            type: Object,
            default: ()=> ({})
        }
    },
    watch: {
        value: async function(v) {
            if (this._.isEmpty(v)) return
            this.loading = true
            this.order_current = (await orderCurrent(this.value.id)).data
            this.form.interval = this.order_current.interval
            this.form.info.volume_id = this.value.id
            setTimeout(()=> {
                this.form.config = {
                    disk: this.value.disk,
                    disk_min: this.value.disk,
                    disk_type: this.value.disk_type.toLowerCase()
                }
            }, 1)
            this.loading = false
            this.showModal()
        },
        'form.config': function (val) {
            if (this.func_delay !== null)
                clearTimeout(this.func_delay)
            if (!(val.disk > this.value.disk)) {
                this.disableCreateOrder = true
                this.money_info = null
            } else {
                this.disableCreateOrder = false
                if (_.isEmpty(this.order_current)) return
                this.func_delay = this.getMoney()
            }
        }
    },
    data() {
        return {
            visible: false,
            form: {
                type: ORDER_RESIZE_VOLUME,
                config: {},
                interval: '',
                info: {
                    volume_id: ''
                }
            },
            fetchCreateOrder: false,
            disableCreateOrder: true,
            currency,
            order_current: {},
            func_delay: null,
            money_info: {}
        };
    },
    methods: {
        showModal() {
            this.visible = true;
        },
        handleCancel(e) {
            this.$emit('input', {})
            this.visible = false;
            this.snapshots = []
        },
        getMoney() {
            return setTimeout(()=> {
                this.loading = true
                OrderService.calculate(this._.merge({'type': ORDER_TYPE_VOLUME}, this.form.config))
                .then(res => {
                    if (this._.isObject(res)) {
                        this.loading = false
                        this.money_info = this._.get(res, `data.${this.order_current.interval}`, {})
                    }
                })
            }, 450)
        },
        createOrder() {
            this.fetchCreateOrder = true
            OrderService.create(this.form)
            .then(res => {
                this.fetchCreateOrder = false
                if (this._.isObject(res) && res.status == 1) {
                    notification.success({
                        message: 'Tạo đơn hàng thành công'
                    })
                    this.$router.push({name: 'billing.payment', params: {id: res.data.invoice_id}})
                } else {
                    notification.error({
                        message: res.message
                    })
                }
            })
        }
    },
    mounted() {
      this.form.config = {
        disk: this.value.disk,
        disk_type: this.value.disk_type,
      }
    }
};
</script>