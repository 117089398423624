<template>
    <a-modal 
        :title="$t('volume.attach.title') + ` [${value.name}] ` +  $t('volume.attach.to') + ` Server`"
        v-model="visible"
        :okText="$t('popup.confirm')"
        :cancelText="$t('popup.cancel')"
        :confirmLoading="confirmLoading"
        @ok="handleSubmit"
        @cancel="handleCancel"
        >
        <a-form :form="volumeInfo" :label-col="{ span: 24 }" :wrapper-col="{ span: 24 }" @submit="handleSubmit">
            <a-form-item :label="$t('volume.actionCreate.form.titleSelectServer')">
                <a-select
                    :options="servers"
                    :placeholder="$t('volume.actionCreate.form.placeholderSelectServer')"
                    :loading="loadingServer"
                    v-decorator="['server_id', {rules: rules.server_id}]"
                >
                </a-select>
            </a-form-item>
        </a-form>
    </a-modal>
</template>
<script>
import attach from "@/services/volume/attach"
import { notification } from 'ant-design-vue'
import servers from "@/services/server/listing"

export default {
    props: {
        value: {
            type: Object,
            default: ()=> ({})
        }
    },
    watch: {
        value: function(v) {
            if (this._.isEmpty(v)) return
            if (this._.isEmpty(this.servers)) {
                this.loadingServer = true
                servers()
                .then(res=> {
                    if (this._.isObject(res)) {
                        this._.forEach(res, (row)=> {
                            this.servers.push({
                                label: row.name,
                                value: row.id
                            })
                        })
                    }
                    this.loadingServer = false
                })
            }
            this.showModal()
        }
    },
    data() {
        return {
            visible: false,
            volumeInfo: this.$form.createForm(this, { name: 'volumeInfo' }),
            rules: {
                server_id: [
                    { required: true, message: 'Vui lòng chọn server để gắn.', trigger: 'change' }
                ]
            },
            loadingServer: false,
            confirmLoading: false,
            servers: []
        };
    },
    created() {
        
    },
    methods: {
        showModal() {
            this.visible = true;
        },
        handleCancel(e) {
            this.$emit('input', {})
            this.visible = false;
        },
        handleSubmit(e) {
            e.preventDefault();
            this.volumeInfo.validateFields((err, values) => {
                if (err) {
                    return;
                }
                this.confirmLoading = true
                attach(this.value.id, values)
                .then(res => {
                    this.confirmLoading = false
                    if (this._.isObject(res)) {
                        this.visible = false
                        notification.success({
                            message: this.$t('notify.success.attachVolume')
                        })
                        this.$emit('reload')
                    }
                })
            });
        },
    },
};
</script>