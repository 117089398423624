<template>
    <a-modal 
        :title="$t('volume.restore.subTitle') + ` [${value.name}]`"
        v-model="visible"
        :okText="$t('popup.confirm')"
        :cancelText="$t('popup.cancel')"
        :confirmLoading="confirmLoading"
        @ok="handleSubmit"
        @cancel="handleCancel"
        >
        <p v-if="!loadingSnapshot && snapshots.length < 1">
          {{$t('volume.restore.desc')}}
          <a href="javascript:void(0)" @click="gotoCreateSnapshot()">{{$t('here')}}</a>
        </p>
        <p v-else>
          {{$t('volume.restore.descSelectSnapshot')}}
        </p>
        <a-form :form="volumeInfo" :label-col="{ span: 24 }" :wrapper-col="{ span: 24 }" @submit="handleSubmit">
            <a-form-item :label="$t('volume.actionCreate.titleSelectSnapshot')">
                <a-select
                    :options="snapshots"
                    :placeholder="$t('volume.actionCreate.placeholderSelectSnapshot')"
                    :loading="loadingSnapshot"
                    v-decorator="['snapshot_id', {rules: rules.server_id}]"
                >
                </a-select>
            </a-form-item>
        </a-form>
    </a-modal>
</template>
<script>
import restore from "@/services/volume/restore"
import { notification } from 'ant-design-vue'
import snapshots from "@/services/snapshot/listing";

export default {
    props: {
        value: {
            type: Object,
            default: ()=> ({})
        }
    },
    watch: {
        value: function(v) {
            if (this._.isEmpty(v)) return
            this.loadingSnapshot = true
            snapshots({volume_id: v.id})
            .then(res=> {
                if (this._.isObject(res)) {
                    this._.forEach(res, (row)=> {
                        this.snapshots.push({
                            label: row.name,
                            value: row.id
                        })
                    })
                }
                this.loadingSnapshot = false
            })
            this.showModal()
        }
    },
    data() {
        return {
            visible: false,
            volumeInfo: this.$form.createForm(this, { name: 'volumeInfo' }),
            rules: {
                server_id: [
                    { required: true, message:  this.$t('volume.actionCreate.optionUncheckSnapshot'), trigger: 'change' }
                ]
            },
            loadingSnapshot: false,
            confirmLoading: false,
            snapshots: []
        };
    },
    created() {
        
    },
    methods: {
        showModal() {
            this.visible = true;
        },
        handleCancel(e) {
            this.$emit('input', {})
            this.visible = false;
            this.snapshots = []
        },
        gotoCreateSnapshot() {
            this.$router.push({name: 'snapshot.create'})
        },
        handleSubmit(e) {
            e.preventDefault();
            this.volumeInfo.validateFields((err, values) => {
                if (err) {
                    return;
                }
                this.confirmLoading = true
                restore(this.value.id, values)
                .then(res => {
                    this.confirmLoading = false
                    if (this._.isObject(res)) {
                        this.visible = false
                        notification.success({
                            message: 'Khôi phục dữ liệu thành công!'
                        })
                    }
                })
            });
        },
    },
};
</script>